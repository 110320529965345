export async function getComponentTranslations(
  locale: string,
  componentKey: string
): Promise<Record<string, string> | void> {
  // dynamically import translation components by locale
  // webpack will only chunk files in @volvo-cars/ packages that match the webpackInclude regex
  try {
    const allTranslations = (await import(
      /* webpackInclude: /[\\/]i18n[\\/]i18n.js$/ */
      `@volvo-cars/${componentKey}/i18n/i18n.js`
    )) as Record<string, Record<string, string>>;
    const translations: Record<string, string> = {};
    for (const [key, strings] of Object.entries(allTranslations)) {
      translations[key] = strings[locale] || strings[locale.split('-')[0]];
    }
    return translations;
  } catch (error: unknown) {
    console.error(error);
  }
}
