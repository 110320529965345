import { dictionariesId } from './constants';
import { getComponentTranslations } from './getComponentTranslations';

type Translations = Record<string, Record<string, Record<string, string>>>;
type Listener = (translator: Translator) => any;
export interface Options {
  skipEmit?: boolean;
}
export class Translator {
  public cache: Translations;
  public currentLocale?: string;
  protected changeListeners: Listener[];
  constructor() {
    this.cache = this.getTranslations();
    this.setLocale = this.setLocale.bind(this);
    this.translate = this.translate.bind(this);
    this.updateCache = this.updateCache.bind(this);
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
    this.emitChange = this.emitChange.bind(this);
    this.changeListeners = [];
  }
  public emitChange() {
    this.changeListeners.forEach((listener) => listener(this));
  }
  public subscribe(listener: Listener, options?: { once: boolean }) {
    if (
      options?.once &&
      this.changeListeners.find((cachedListener) => listener === cachedListener)
    ) {
      return;
    }
    this.changeListeners.push(listener);
  }
  public unsubscribe(listener: Listener) {
    this.changeListeners = this.changeListeners.filter(
      (cachedListener) => listener !== cachedListener
    );
  }

  // Add all items in a component whenever a single item is requested
  updateCache = (
    componentKey: string,
    value: Record<string, string>,
    options?: Options
  ) => {
    const locale = this.currentLocale;
    if (!locale) return;
    const cacheByLocale = this.cache?.[locale];
    if (cacheByLocale) {
      this.cache = {
        ...this.cache,
        [locale]: {
          ...cacheByLocale,
          [componentKey]: value,
        },
      };
    } else {
      this.cache = {
        ...this.cache,
        [locale]: { [componentKey]: value },
      };
    }
    if (!options?.skipEmit) {
      this.emitChange();
    }
  };
  public setLocale(locale: string, options?: Options) {
    if (this.currentLocale !== locale || !this.currentLocale) {
      this.currentLocale = locale;
      if (!options?.skipEmit) {
        this.emitChange();
      }
    }
  }
  private async cacheHandler(
    locale: string,
    componentKey: string,
    options?: Options
  ) {
    const data = await getComponentTranslations(locale, componentKey);
    if (data) {
      this.updateCache(componentKey, data, options);
    }
  }

  private getTranslations(): Record<
    string,
    Record<string, Record<string, string>>
  > {
    if (typeof window === 'undefined') return {};
    const element = document.getElementById(dictionariesId);
    if (!element) return {};
    try {
      return JSON.parse(element.innerHTML);
    } catch (error) {
      console.error(error);
      return {};
    }
  }
  public translate(key: string, options?: { skipEmit?: boolean }) {
    const locale = this.currentLocale;
    if (!locale) {
      return;
    }
    const libraryByLocale = this.cache?.[locale];
    const [componentKey, itemKey] = key.split('.');
    if (libraryByLocale) {
      const component = libraryByLocale[componentKey];
      if (component) {
        return component[itemKey];
      } else {
        this.cacheHandler(locale, componentKey, options);
      }
    } else {
      this.cacheHandler(locale, componentKey, options);
    }
  }
}
