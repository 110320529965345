import React, { createContext, useContext, useEffect, useState } from 'react';
import { Translator } from './Translator';

const TranslatorContext = createContext<{
  translator: Translator | null;
}>({ translator: null });

export interface TranslatorProviderProps {
  children: React.ReactNode;
  translator: Translator;
  locale: string;
}

export const TranslatorProvider: React.FC<TranslatorProviderProps> = ({
  translator: originalTranslator,
  locale,
  children,
}) => {
  const [value, setValue] = useState({
    translator: originalTranslator,
  });
  const { translator } = value;
  // trigger only once on initail render, required for SSR
  useState(() => translator.setLocale(locale, { skipEmit: true }));

  useEffect(() => {
    translator.setLocale(locale);
  }, [locale, translator]);

  useEffect(() => {
    const callback = (translator: Translator) => {
      setValue({ translator });
    };
    translator.subscribe(callback, { once: true });
    return () => {
      translator.unsubscribe(callback);
    };
  }, [translator]);

  return (
    <TranslatorContext.Provider value={value}>
      {children}
    </TranslatorContext.Provider>
  );
};

export const useTranslator = () => useContext(TranslatorContext);
