import { useEffect, useState } from 'react';
import { useTranslator } from './TranslatorProvider';
/**
 *
 * @param key string in the format of package.item excluding @volvo-cars. ex: react-accordion.item1
 * @param options
 * @returns string | undefined
 */
export const useTranslate = (key: string, options?: { disabled?: boolean }) => {
  // careful not to destructure here, value will change but value.translator will not
  const value = useTranslator();
  const isDisabled = !!options?.disabled;
  const [translation, setTranslation] = useState(() =>
    isDisabled
      ? undefined
      : value.translator?.translate(key, { skipEmit: true })
  );
  useEffect(() => {
    setTranslation(isDisabled ? undefined : value.translator?.translate(key));
  }, [value, key, isDisabled]);

  return translation;
};
