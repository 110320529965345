import React from 'react';
import type { Translator } from '@volvo-cars/react-shared-translations';
import { dictionariesId } from './constants';

export interface TranslatorCacheProps {
  translator: Translator;
}

export const TranslatorCache: React.FC<TranslatorCacheProps> = ({
  translator,
}) => {
  try {
    return (
      <script
        id={dictionariesId}
        type="application/json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(translator.cache) }}
      />
    );
  } catch (error) {
    return null;
  }
};
